import { fetchUrl, fethConfig } from './fetch-config'
import { fetchRefresh, fetchSendError } from './utils'
import { CONFIG_APP } from '../../config'
import { CookieService } from '../../server'

interface BaseFetchArgs {
  params?: Record<string, string>
  url?: string
  method: string
  payload?: object | string
}

export const baseFetch = async <T>(args: BaseFetchArgs): Promise<T> => {
  const { method, url, params, payload } = args

  const _config = fethConfig(method, payload)
  const _url = fetchUrl(url, params)

  try {
    let response = await window.fetch(_url, _config)

    if (response.status === 401) {
      const refresh = await fetchRefresh()

      const { newAccessToken } = refresh

      if (!newAccessToken) {
        console.log('Failed get new token')
        CookieService.remove(CONFIG_APP.REFRESH_TOKEN_COOKIE)
        CookieService.remove(CONFIG_APP.ACCESS_TOKEN_COOKIE)
        window.location.href = '/'
      } else {
        _config.headers.Authorization = `Bearer ${newAccessToken}`

        response = await fetch(_url, _config)
      }
    }

    const json = await response.json()

    if (response.status !== 401 && !response.ok && json.message) {
      await fetchSendError({
        status: response.status,
        message: json.message,
        url: response.url,
      })
    }

    return json
  } catch (error) {
    console.error('Failed:', error)
    throw error
  }
}
