import type { ResponseType } from '../../types/response-type'
import { baseFetch } from './baseApi'

export class FetchService {
  static get<T>({
    url,
    params,
  }: {
    url: string
    params?: Record<string, string>
  }): Promise<ResponseType<T>> {
    return baseFetch({ url, method: 'GET', params })
  }

  static post<T>({
    url,
    payload,
    params,
  }: {
    url: string
    payload?: object | FormData | string
    params?: Record<string, string>
  }): Promise<ResponseType<T>> {
    return baseFetch({ url, payload, method: 'POST', params })
  }

  static patch<T>({
    url,
    payload,
    params,
  }: {
    url: string
    payload: object
    params?: Record<string, string>
  }): Promise<ResponseType<T>> {
    return baseFetch({ url, payload, method: 'PATCH', params })
  }

  static put<T>({
    url,
    payload,
    params,
  }: {
    url: string
    payload?: object
    params?: Record<string, string>
  }): Promise<ResponseType<T>> {
    return baseFetch({ url, payload, method: 'PUT', params })
  }

  static delete<T>({
    url,
    params,
    payload,
  }: {
    url: string
    params?: Record<string, string>
    payload?: Object
  }): Promise<ResponseType<T>> {
    return baseFetch({ url, payload, method: 'DELETE', params })
  }

  static getBlob<T>({
    query,
    variables,
  }: {
    query: string
    variables?: object
  }): Promise<ResponseType<T>> {
    return baseFetch({ method: 'POST', payload: { query, variables } })
  }

  static graphQl<T>({
    query,
    variables,
  }: {
    query: string
    variables?: object
  }): Promise<ResponseType<T>> {
    return baseFetch({ method: 'POST', payload: { query, variables } })
  }
}
