const AddPhotoSvg = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2222 12.222C12.2222 10.4984 13.8217 8.88867 15.5555 8.88867H24.4444C26.1782 8.88867 27.7777 10.4984 27.7777 12.222H30C31.8403 12.222 33.3333 13.715 33.3333 15.5553V27.9998C33.3333 29.7224 31.9192 31.1109 30.1855 31.1109H9.8144C8.08072 31.1109 6.66663 29.7224 6.66663 27.9998V15.5553C6.66663 13.715 8.15964 12.222 9.99996 12.222H12.2222ZM15.5555 11.1109C15.0522 11.1109 14.4444 11.7226 14.4444 12.222V13.3331C14.4444 13.9468 13.9469 14.4442 13.3333 14.4442H9.99996C9.38694 14.4442 8.88885 14.9423 8.88885 15.5553V27.9998C8.88885 28.4859 9.29903 28.8887 9.8144 28.8887H30.1855C30.7009 28.8887 31.1111 28.4859 31.1111 27.9998V15.5553C31.1111 14.9423 30.613 14.4442 30 14.4442H26.6666C26.053 14.4442 25.5555 13.9468 25.5555 13.3331V12.222C25.5555 11.7226 24.9477 11.1109 24.4444 11.1109H15.5555ZM20 15.5553C23.0681 15.5553 25.5555 18.0428 25.5555 21.1109C25.5555 24.179 23.0681 26.6664 20 26.6664C16.9319 26.6664 14.4444 24.179 14.4444 21.1109C14.4444 18.0428 16.9319 15.5553 20 15.5553ZM20 17.7776C18.1592 17.7776 16.6666 19.2701 16.6666 21.1109C16.6666 22.9517 18.1592 24.4442 20 24.4442C21.8408 24.4442 23.3333 22.9517 23.3333 21.1109C23.3333 19.2701 21.8408 17.7776 20 17.7776Z"
        fill="#BFBFBF"
      />
    </svg>
  );
};

export { AddPhotoSvg };
