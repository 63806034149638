const TrashSvg = ({  width="12",   height="12", color }: { width?: string, height?: string, color?: string }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5001 3.36523C10.4901 3.36523 10.4751 3.36523 10.4601 3.36523C7.81508 3.10023 5.17508 3.00023 2.56008 3.26523L1.54008 3.36523C1.33008 3.38523 1.14508 3.23523 1.12508 3.02523C1.10508 2.81523 1.25508 2.63523 1.46008 2.61523L2.48008 2.51523C5.14008 2.24523 7.83508 2.35023 10.5351 2.61523C10.7401 2.63523 10.8901 2.82023 10.8701 3.02523C10.8551 3.22023 10.6901 3.36523 10.5001 3.36523Z"
        fill={color ? color :"black"}
      />
      <path
        d="M4.24988 2.86C4.22988 2.86 4.20988 2.86 4.18488 2.855C3.98488 2.82 3.84488 2.625 3.87988 2.425L3.98988 1.77C4.06988 1.29 4.17988 0.625 5.34488 0.625H6.65488C7.82488 0.625 7.93488 1.315 8.00988 1.775L8.11988 2.425C8.15488 2.63 8.01488 2.825 7.81488 2.855C7.60988 2.89 7.41488 2.75 7.38488 2.55L7.27488 1.9C7.20488 1.465 7.18988 1.38 6.65988 1.38H5.34988C4.81988 1.38 4.80988 1.45 4.73488 1.895L4.61988 2.545C4.58988 2.73 4.42988 2.86 4.24988 2.86Z"
        fill={color ? color :"black"}
      />
      <path
        d="M7.60495 11.3748H4.39495C2.64995 11.3748 2.57995 10.4098 2.52495 9.62979L2.19995 4.59479C2.18495 4.38979 2.34495 4.20979 2.54995 4.19479C2.75995 4.18479 2.93495 4.33979 2.94995 4.54479L3.27495 9.57979C3.32995 10.3398 3.34995 10.6248 4.39495 10.6248H7.60495C8.65495 10.6248 8.67495 10.3398 8.72495 9.57979L9.04995 4.54479C9.06495 4.33979 9.24495 4.18479 9.44995 4.19479C9.65495 4.20979 9.81495 4.38479 9.79995 4.59479L9.47495 9.62979C9.41995 10.4098 9.34995 11.3748 7.60495 11.3748Z"
        fill={color ? color :"black"}
      />
      <path
        d="M6.83004 8.625H5.16504C4.96004 8.625 4.79004 8.455 4.79004 8.25C4.79004 8.045 4.96004 7.875 5.16504 7.875H6.83004C7.03504 7.875 7.20504 8.045 7.20504 8.25C7.20504 8.455 7.03504 8.625 6.83004 8.625Z"
        fill={color ? color :"black"}
      />
      <path
        d="M7.25 6.625H4.75C4.545 6.625 4.375 6.455 4.375 6.25C4.375 6.045 4.545 5.875 4.75 5.875H7.25C7.455 5.875 7.625 6.045 7.625 6.25C7.625 6.455 7.455 6.625 7.25 6.625Z"
        fill={color ? color :"black"}
      />
    </svg>
  );
};

export { TrashSvg };
