import { METODATA_TITLES, METODATA_TITLES_DINAMIC } from '../constants/metodata'
import { ENDPOINT_LIST } from '../constants/endpoints'

export const CONFIG_APP = {
  METODATA_TITLES,
  ENDPOINT_LIST,
  METODATA_TITLES_DINAMIC,
  API_ENDPOINT: BACKEND_URL,
  ACCESS_TOKEN_LOCALE_STORAGE: 'ACCESS_TOKEN',
  REFRESH_TOKEN_LOCALE_STORAGE: 'REFRESH_TOKEN',
  ACCESS_TOKEN_COOKIE: 'ACCESS_TOKEN',
  REFRESH_TOKEN_COOKIE: 'REFRESH_TOKEN',
  COOKIE_TIME: 3600,
  FETH_CACHE_TIME: 120000,
  EMPTY_AVATAR:
    'https://res.cloudinary.com/ds289tkqj/image/upload/v1697021848/startap/i_xfkaq4.webp',
  PATH_UPLOADS_AVATARS: `${BACKEND_URL}/public/uploads/avatars/`,
  PAHT_UPLOADS_PHOTOS: `${BACKEND_URL}/public/uploads/photos`,
  PATH_UPLOADS_FILES: `${BACKEND_URL}/uploads/files/`,
  START_PAGE_AFTER_LOGIN: 'orders',
  REACT_APP_VERSION: REACT_APP_VERSION,
  REACT_APP_STATUS: REACT_APP_STATUS,
} as const
