import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuthSelect, useSideIsOpen, useToggleSideMenu } from '@/entities'
import { Avatar } from '@/shared/ui/avatar/Avatar'
import { CONFIG_APP } from '@/shared/config'
import { menuSystem } from '@/shared/constants/menu-side-bar'
import { MenuItem } from '../menu-item/MenuItem'

import styles from './LayoutAside.module.scss'

const LayoutAside = () => {
  const [currentItemMenu, setCurrentItemMenu] = useState<string>('')
  const { isOpen } = useSideIsOpen()
  const { toggleSideMenu } = useToggleSideMenu()
  const { user } = useAuthSelect()
  const { pathname } = useLocation()
  const navigation = useNavigate()

  const handleClickOutside = () => {
    return toggleSideMenu({ value: false })
  }

  const handleNavigationMenu = useCallback(
    (href: string | undefined) => {
      if (typeof href === 'undefined') return

      handleClickOutside()

      navigation(href)
    },
    [isOpen],
  )

  const handleActiveMenu = useCallback(
    ({ value, path }: { value: string; path: string | undefined }) => {
      if (value === currentItemMenu) {
        setCurrentItemMenu(() => '')
      } else {
        setCurrentItemMenu(() => value)
      }

      if (path) {
        handleNavigationMenu(path)
      }
    },
    [currentItemMenu],
  )

  const activePath = useMemo(() => pathname, [pathname])

  return (
    <>
      {isOpen && (
        <div
          onClick={handleClickOutside}
          className={styles.shadowContent}
        ></div>
      )}
      <aside
        className={
          isOpen ? `${styles.root} ${styles.mobileSideOpen}` : styles.root
        }
      >
        <header className={styles.asideHeader}>
          <picture>
            <div
              className={styles.asideHeaderContent}
              style={{ cursor: 'pointer' }}
              onClick={() => handleNavigationMenu('/account')}
            >
              <Avatar
                src={`${CONFIG_APP.PATH_UPLOADS_AVATARS}${user?.avatar}`}
              />
              <h5>{user?.username || 'Admin'}</h5>
            </div>
          </picture>
        </header>

        <nav className={styles.navlist}>
          <ul className={styles.menuList}>
            {menuSystem.map((menu) => (
              <MenuItem
                onClicNavChildren={handleNavigationMenu}
                activePath={activePath}
                onClick={handleActiveMenu}
                isOpen={currentItemMenu === menu.title}
                key={menu.title}
                icon={menu.icon}
                title={menu.title}
                href={menu.href}
                list={menu.list}
              />
            ))}
          </ul>
        </nav>

        <p className={styles.systemInfo}>
          v{CONFIG_APP.REACT_APP_VERSION} {CONFIG_APP.REACT_APP_STATUS}
        </p>
      </aside>
    </>
  )
}

export { LayoutAside }
