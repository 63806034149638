export const NAV = {
  ACCOUNT: 'account',
  DASHBOARD: 'dashboard',
  USER_INFO: 'users/info/:id',
  USER_EDIT: 'users/edit/:id',
  USER_ADD: 'users/add',
  USER_LIST: 'users',
  WORKS_LIST: 'works',
  WORKS_INFO: 'works/info/:id',
  ORDERS_LIST: 'orders',
  ORDER_INFO: 'orders/info/:id',
  REVIEWS_LIST: 'reviews',
  REVIEW_INFO: 'reviews/info/:id',
  REVIEWS_EDIT: 'reviews/edit/:id',
  REVIEW_ADD: 'reviews/add',
  WORKS_ADD: 'works/add',
  WORKS_EDIT: 'works/edit/:id',
  SERVICES_LIST: 'services',
  SERVICES_INFO: 'services/info/:id',
  SERVICES_ADD: 'services/add',
  SERVICES_EDIT: 'services/edit/:id',
  SERVICES_SCHEMA: 'services-schema',
  METRICS_LIST: 'metrics',
  METRICS_INFO: 'metrics/info/:id',
  METRICS_ADD: 'metrics/add',
  METRICS_EDIT: 'metrics/edit/:id',
  SEO_LIST: 'seo',
  SEO_INFO: 'seo/info/:id',
  SEO_ADD: 'seo/add',
  SEO_EDIT: 'seo/edit/:id',
  EMAIL: 'email',
  FEEDBACK_EDIT: 'feedbacks/edit/:id',
  FEEDBACK_LIST: 'feedbacks',
  PRICES_EDIT: 'prices/edit/:id',
  PRICES_ADD: 'prices/add',
  PRICES_LIST: 'prices',
  EMAIL_TEMPLATES: 'email-templates',
  EMAIL_HISTORY: 'email-history',
  EMAIL_CREATE: 'email-templates/create',
  EMAIL_EDIT: 'email-templates/edit/:id',
  LOGS_LIST: 'app-logs',
  // APP_STATISTICS: 'app-statistics',
  APP_STATISTICS_EVENTS: 'events',
  APP_STATISTICS_ADD: 'events/add',
  APP_STATISTICS_EDIT: 'events/edit/:id',
  LOGS: 'logs',
  COMMON_SETTINGS: 'common-settings',
  UPDATE_SYSTEM: 'update-system',
  SETTINGS: 'settings',
  CONFIGURATION_KEYS: 'configuration/keys',
  CONFIGURATION_KEYS_ADD: 'configuration/keys/add',
  CONFIGURATION_KEYS_EDIT: 'configuration/keys/edit/:id',
  LOGIN: 'login',
  MAIN: '/',
  NO_PAGE: '*',
  //------Template---------
}
