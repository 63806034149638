import { PayloadAction } from "@reduxjs/toolkit/dist/createAction";
import { AuthInitState } from "./types";

export const reducers = {

  logoutAuth(state: AuthInitState) {
    state.authorization = false;
    state.user = null;
  },

  changeUserInfo (state: AuthInitState, action: PayloadAction<{ name: string, email: string, phone: string, fio: string, avatar: string }>) {
    const { email, name, phone, fio, avatar } = action.payload

    if (state.user) {
      state.user = {
        ...state.user,
        username: name,
        phone,
        email,
        fio,
        avatar
      }
    }
  },

  changeUserAvatar (state: AuthInitState, action: PayloadAction<{ avatar: string }>) {
    const { avatar } = action.payload

    if (state.user) {
      state.user = {
        ...state.user,
        avatar,
      }
    }
  },
  rememberToggle (state: AuthInitState) {
    state.isRemember = !state.isRemember
  },

  setToogleLogoutModal (state: AuthInitState) {
    if (state.isLogoutModalOpen) {
      state.isLogoutModalOpen = false
    } else {
      state.isLogoutModalOpen = true
    }
  },

  setRegistrationResponse (state: AuthInitState, action: PayloadAction <{ value: 'success' | 'error' | null }>) {
    state.registrationResponse = action.payload.value;
  },
  
};
