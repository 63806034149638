import UsersSvg from '../../../public/assets/svg/users-svgrepo-com.svg'
import FeedbackSvg from '../../../public/assets/svg/feedback-svgrepo-com.svg'
import MailSvg from '../../../public/assets/svg/mail-svgrepo-com.svg'
import TemplateSvg from '../../../public/assets/svg/template-svgrepo-com.svg'
import HistorySvg from '../../../public/assets/svg/history-svgrepo-com.svg'
import SettingSvg from '../../../public/assets/svg/settings-2-svgrepo-com.svg'
import SettingOptionSvg from '../../../public/assets/svg/settings-menu-option.svg'
import PortfolioSvg from '../../../public/assets/svg/portfolio-svgrepo-com.svg'
import KeySvg from '../../../public/assets/svg/key-svgrepo-com.svg'
import MonitorSvg from '../../../public/assets/svg/monitor-svgrepo-com.svg'
import ErrorSvg from '../../../public/assets/svg/error-16-svgrepo-com.svg'
import EventSvg from '../../../public/assets/svg/events-svgrepo-com.svg'
import SystemSvg from '../../../public/assets/svg/system-expired-line-svgrepo-com.svg'
import SystemSettingsSvg from '../../../public/assets/svg/settings-general.svg'
import SystemLogsSvg from '../../../public/assets/svg/code-tech-dev-svgrepo-com.svg'
import SystemUpdateSvg from '../../../public/assets/svg/update-svgrepo-com.svg'
import ReviewSvg from '../../../public/assets/svg/thumb-up-svgrepo-com.svg'
import OrderSvg from '../../../public/assets/svg/order-svgrepo-com.svg'
import PriceSvg from '../../../public/assets/svg/price-tag-svgrepo-com.svg'
import SeoSvg from '../../../public/assets/svg/search-svgrepo-com.svg'
import MetricsSvg from '../../../public/assets/svg/metrics-svgrepo-com.svg'

import ServicesSvg from '../../../public/assets/svg/service-svgrepo-com.svg'

import type { SvgIconProps } from '../types/svgIconComponsets'
//------------Template-----------------

export type MenuDesingModel = {
  href: string
  icon: SvgIconProps
  title: string
}

type MenuSystemModel = {
  href?: string
  icon: SvgIconProps
  title: string
  list?: MenuDesingModel[]
}

export const menuSystem: MenuSystemModel[] = [
  {
    href: '/users',
    icon: UsersSvg,
    title: 'Пользователи',
  },

  {
    href: '/feedbacks',
    icon: FeedbackSvg,
    title: 'Обратная связь',
  },

  {
    href: '/services',
    icon: ServicesSvg,
    title: 'Услуги',
    list: [
      {
        href: '/services-schema',
        icon: TemplateSvg,
        title: 'Схема',
      },
    ],
  },
  {
    href: '/works',
    icon: PortfolioSvg,
    title: 'Наши работы',
  },
  {
    href: '/orders',
    icon: OrderSvg,
    title: 'Заявки',
  },
  {
    href: '/reviews',
    icon: ReviewSvg,
    title: 'Отзывы',
  },
  {
    href: '/seo',
    icon: SeoSvg,
    title: 'SEO',
  },
  {
    href: '/prices',
    icon: PriceSvg,
    title: 'Прайс',
  },
  {
    href: '/metrics',
    icon: MetricsSvg,
    title: 'Метрика',
  },
  {
    icon: MailSvg,
    title: 'Почтовые шаблоны',
    list: [
      {
        href: '/email-templates',
        icon: TemplateSvg,
        title: 'Шаблоны',
      },
      {
        href: '/email-history',
        icon: HistorySvg,
        title: 'История',
      },

      {
        href: '/email',
        icon: MailSvg,
        title: 'Почта',
      },
    ],
  },
  {
    icon: SettingSvg,
    title: 'Настройки',
    list: [
      {
        href: '/settings',
        icon: SettingOptionSvg,
        title: 'Настройки',
      },
      {
        href: '/configuration/keys',
        icon: KeySvg,
        title: 'Ключевой конфигуратор',
      },
    ],
  },
  {
    icon: MonitorSvg,
    title: 'Работа приложения',
    list: [
      {
        href: '/app-logs',
        icon: ErrorSvg,
        title: 'Журналы ошибок',
      },
      {
        href: '/events',
        icon: EventSvg,
        title: 'События',
      },
    ],
  },
  {
    icon: SystemSvg,
    title: 'Система',
    list: [
      {
        href: '/common-settings',
        icon: SystemSettingsSvg,
        title: 'Общие настройки',
      },
      {
        href: '/logs',
        icon: SystemLogsSvg,
        title: 'Логи',
      },
      {
        href: '/update-system',
        icon: SystemUpdateSvg,
        title: 'Обновление системы',
      },
    ],
  },
]
