import styles from "./LoaderCircle.module.scss";

const LoaderCircle = ({
  width = "70px",
  height = "70px",
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      className={styles.svg}
      fill="#5664d2"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 97.541 97.54"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              d="M70.063,27.182c1.398,1.175,3.174,1.821,4.996,1.821c2.312,0,4.488-1.014,5.974-2.782
				c2.76-3.289,2.329-8.211-0.96-10.973c-1.4-1.175-3.176-1.822-5-1.822c-2.311,0-4.487,1.014-5.971,2.782
				C66.341,19.498,66.773,24.419,70.063,27.182z"
            />
            <path
              d="M88.445,36.096c-0.483,0-0.971,0.043-1.448,0.127c-4.485,0.791-7.493,5.086-6.702,9.573
				c0.696,3.955,4.111,6.825,8.119,6.825c0.482,0,0.972-0.043,1.451-0.126c4.485-0.792,7.492-5.086,6.701-9.571
				C95.868,38.968,92.452,36.096,88.445,36.096z"
            />
            <path
              d="M88.158,63.113c-1.328-0.768-2.834-1.172-4.354-1.172c-3.118,0-6.022,1.675-7.579,4.371
				c-1.165,2.019-1.477,4.371-0.872,6.625s2.052,4.139,4.069,5.304c1.329,0.769,2.835,1.174,4.357,1.174
				c3.116,0,6.02-1.674,7.576-4.369C93.761,70.874,92.327,65.521,88.158,63.113z"
            />
            <path
              d="M63.316,78.646c-1.07,0-2.13,0.188-3.15,0.558c-2.31,0.841-4.153,2.532-5.193,4.761c-1.039,2.229-1.148,4.729-0.308,7.04
				c1.32,3.626,4.798,6.063,8.654,6.063c1.07,0,2.13-0.188,3.147-0.559c2.308-0.841,4.15-2.531,5.191-4.764
				c1.04-2.23,1.15-4.73,0.312-7.037C70.651,81.083,67.172,78.646,63.316,78.646z"
            />
            <path
              d="M39.903,78.757c-1.074-0.39-2.188-0.588-3.31-0.588c-4.054,0-7.71,2.562-9.097,6.375
				c-0.886,2.431-0.771,5.06,0.322,7.403c1.092,2.344,3.031,4.121,5.462,5.006c1.072,0.391,2.187,0.587,3.312,0.587
				c4.056,0,7.711-2.562,9.097-6.372c0.884-2.426,0.768-5.055-0.326-7.4C44.268,81.42,42.33,79.641,39.903,78.757z"
            />
            <path
              d="M24.916,65.6c-1.81-3.133-5.183-5.078-8.805-5.078c-1.771,0-3.522,0.472-5.067,1.361c-2.35,1.357-4.03,3.549-4.731,6.166
				c-0.703,2.62-0.343,5.357,1.014,7.706c1.81,3.134,5.184,5.08,8.806,5.08c1.77,0,3.521-0.472,5.065-1.362
				C26.046,76.674,27.714,70.45,24.916,65.6z"
            />
            <path
              d="M11.495,54.991c5.158,0,9.555-3.695,10.453-8.786c0.492-2.797-0.133-5.617-1.762-7.94
				c-1.627-2.326-4.063-3.878-6.861-4.372c-0.62-0.108-1.247-0.163-1.86-0.163c-5.158,0-9.555,3.694-10.453,8.785
				C0.52,45.31,1.145,48.13,2.774,50.456c1.628,2.325,4.065,3.878,6.861,4.371C10.252,54.936,10.878,54.991,11.495,54.991z"
            />
            <path
              d="M24.849,32.319c2.599,0,5.131-0.923,7.13-2.598c2.268-1.903,3.659-4.58,3.918-7.538c0.259-2.958-0.647-5.836-2.551-8.104
				c-2.114-2.52-5.217-3.965-8.511-3.965c-2.603,0-5.135,0.922-7.131,2.597c-2.271,1.906-3.665,4.583-3.923,7.537
				c-0.259,2.952,0.648,5.831,2.555,8.104C18.453,30.873,21.555,32.319,24.849,32.319z"
            />
          </g>
          <circle cx="49.955" cy="12.076" r="12.076" />
        </g>
      </g>
    </svg>
  );
};

export { LoaderCircle };
