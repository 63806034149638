import type * as authDto from "../DTO/auth-dto";
import { FetchService } from "../../../shared/api/fetch-api";
import { CONFIG_APP } from "../../../shared/config";


export const authorization = async (payload: authDto.AuthorizationInput): Promise<authDto.AuthorizationResponse> => {
  const url = CONFIG_APP.ENDPOINT_LIST.authEndpoints.login;

  return FetchService.post({ url, payload });
} 

export const registrationUser = (payload: authDto.RegistrationUserInput): Promise<authDto.RegistrationUserResponse> => {
  const url = CONFIG_APP.ENDPOINT_LIST.authEndpoints.register;

  return FetchService.post({ url, payload });
};

export const fethCurrentInfo = (): Promise<authDto.UserCurrentInfoResponse> => {
  const url = CONFIG_APP.ENDPOINT_LIST.userEndpoints.getCurrent;

  //@ts-ignore
  return FetchService.get({ url });
};

export const fethChangeAvatar = (payload: FormData): Promise<authDto.ChangeAvatarResponse> => {
  const url = CONFIG_APP.ENDPOINT_LIST.userEndpoints.changeAvatar;

  return FetchService.put({ url, payload });
};

export const fethChangeUserMainInfo = (payload: authDto.ChangeUserMainInfoInput): Promise<authDto.ChangeUserMainInfoResponse> => {
  const url = CONFIG_APP.ENDPOINT_LIST.userEndpoints.changeInfo;

  return FetchService.put({ url, payload });
};

export const fethChangeUserPassword = (payload: authDto.ChangeUserPasswordInput): Promise<authDto.ChangeUserPasswordResponse> => {
  const url = CONFIG_APP.ENDPOINT_LIST.userEndpoints.changePassword;

  return FetchService.put({ url, payload });
};

export const fethGetAllUsers = (params: authDto.FethAllUsersParams): Promise<authDto.GetAllUsersResponse> => {
  const url = CONFIG_APP.ENDPOINT_LIST.adminEndpoints.getUsers;

  return FetchService.get({ url, params });
};

export const fethRecoveryPassword = (payload: authDto.RecoveryPasswordPayload): Promise<authDto.RecoveryPasswordResponse> => {
  const url = CONFIG_APP.ENDPOINT_LIST.authEndpoints.recoveryPassword;

  return FetchService.post({ url, payload });
};
export const fethRecoveryChangePassword = ({ payload, key }: { key: string, payload :  { password: string } }): Promise<authDto.RecoveryChangePasswordResponse> => {
  const url = CONFIG_APP.ENDPOINT_LIST.authEndpoints.recoveryChangePassword(key);

  return FetchService.post({ url, payload });
};
