import type { ChildrenNodeType } from '../../types/children';

import styles from './PerfectScrollContainer.module.scss';


type Props = {
  children: ChildrenNodeType;
}

const PerfectScrollContainer = ({ children }: Props) => {

  return (
<div className={styles.root}>
      {children}
</div>
  );
};

export { PerfectScrollContainer };