import { CONFIG_APP } from "../../config";
import { CookieService, LocalStorage } from "../../service";


type RefreshTokenResponse = {
  "status": string,
  "message": string,
  "data": {
    "tokens": {
      "accessToken": string,
      "refreshToken": string
    }
  }
}

export const getToken = () => {
  const tokenLocal = LocalStorage.get(CONFIG_APP.ACCESS_TOKEN_LOCALE_STORAGE, '');
  const tokenCookie = CookieService.get(CONFIG_APP.ACCESS_TOKEN_COOKIE);

  return tokenLocal || tokenCookie || '';
};

export const getRefreshToken = () => {
  const refreshLocal = LocalStorage.get(CONFIG_APP.REFRESH_TOKEN_LOCALE_STORAGE, '')
  const refreshCookie = CookieService.get(CONFIG_APP.REFRESH_TOKEN_COOKIE)

  return refreshLocal || refreshCookie || ''
};

export async function fetchRefresh(): Promise<any> {
  const refreshUrl = new URL(`${CONFIG_APP.API_ENDPOINT}/api/refresh`);
  const refreshToken = getRefreshToken();
  
      const fetchRefreshToken = await fetch(refreshUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${refreshToken}`
      }
    });

    const json: RefreshTokenResponse = await fetchRefreshToken.json();

    if (json.status === 'success' && fetchRefreshToken.status === 200) {
        CookieService.set(json.data.tokens.accessToken, CONFIG_APP.ACCESS_TOKEN_COOKIE, CONFIG_APP.COOKIE_TIME)
        CookieService.set(json.data.tokens.refreshToken, CONFIG_APP.REFRESH_TOKEN_COOKIE)
        return { newAccessToken: json.data.tokens.accessToken }
    } else {
      return json
    }
}

export async function fetchSendError({ message, status, url }: { message: string, status: number, url: string }): Promise<any> {
      const payloadError = {
        message: message,
        isFatal: true,
        name: `status: ${status} url: ${url}`
      }

       fetch(`${CONFIG_APP.API_ENDPOINT}/api/logs/frontend`, {
        method: 'POST',
        body: JSON.stringify(payloadError),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }
      })
}

export const generateCacheKey = (url: string, method: string, payload: any): string => {
  const payloadString = JSON.stringify(payload);
  const cacheKey = `${url}:${method}:${payloadString}`;
  
  return cacheKey;
};


export const getCacheKey = (method: string, url: string, payload: object | undefined): string | null => {
  if (method !== 'GET') return null;
  
  let cacheKey: string | null = null;

  if (method === 'GET') {
    cacheKey = generateCacheKey(url, method, JSON.stringify(payload))
  }

  return cacheKey
}

export const saveTokenInStorage = (accessToken: string,  refreshToken: string) => {
  window.localStorage.setItem(CONFIG_APP.ACCESS_TOKEN_LOCALE_STORAGE, accessToken);
  window.localStorage.setItem(CONFIG_APP.REFRESH_TOKEN_LOCALE_STORAGE, refreshToken);

  CookieService.set(accessToken, CONFIG_APP.ACCESS_TOKEN_LOCALE_STORAGE);
  CookieService.set(accessToken, CONFIG_APP.REFRESH_TOKEN_LOCALE_STORAGE);
};