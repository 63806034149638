export interface ValidateObjectType {
  [key: string]: string | undefined
  email?: string
  username?: string
  date?: string
  message?: string
  repeadPassword?: string
  login?: string
  password?: string
  newPassword?: string
  oldPassword?: string
}

export class Validator {
  static validate<T extends object>(obj: T, required: (keyof T)[]): T | null {
    const newErrors: T & Partial<ValidateObjectType> = {} as T &
      Partial<ValidateObjectType>

    for (let field in obj) {
      const isRequired = required.includes(field)

      const value = obj[field]

      if (!isRequired && !value) {
        continue
      }

      const validateMethod = this[field as keyof typeof Validator]

      if (validateMethod && typeof validateMethod === 'function') {
        //@ts-ignore
        const error = validateMethod.call(this, value, isRequired)

        if (error) {
          newErrors[field] = error
        }
      }
    }

    if (
      obj.hasOwnProperty('repeadPassword') &&
      obj.hasOwnProperty('password') &&
      !newErrors.hasOwnProperty('password') &&
      !newErrors.hasOwnProperty('repeadPassword')
    ) {
      const error = this.checkForPasswordEquality(
        (obj as ValidateObjectType).password || '',
        (obj as ValidateObjectType).repeadPassword || '',
      )

      if (error) {
        newErrors.repeadPassword = error
      }
    }

    let isError = this.checkErrorValue<T>(newErrors)

    return isError ? newErrors : null
  }

  static checkForPasswordEquality(
    password: string,
    repeadPassword: string,
  ): string | null {
    return password !== repeadPassword ? 'Пароли должны совпадать' : null
  }

  static getEmptyError(field: string): string {
    return `Введите ${field}`
  }

  static getFormatError(field: string): string {
    return `Неверный формат ${field}`
  }

  static checkErrorValue = <T>(objError: T): boolean => {
    for (let fieldErrror in objError) {
      const isErrorValue = objError[fieldErrror]
      if (isErrorValue) {
        return true
      }
    }

    return false
  }

  static email(email: string | undefined): string | null {
    if (!email) {
      return this.getEmptyError('Email')
    }

    const pattern = /^[\w.-]+@[\w.-]+\.\w+$/

    if (!pattern.test(email)) {
      return this.getFormatError('Email')
    }
    return null
  }

  static username(name: string | undefined): string | null {
    if (!name) {
      return this.getEmptyError('имя')
    }
    return null
  }

  static message(message: string | undefined): string | null {
    if (!message) {
      return this.getEmptyError('сообщение')
    }
    return null
  }

  static login(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError('логин')
    }

    if (value && /\s/.test(value)) {
      return 'Логин не должно содержать пробелы'
    }

    return null
  }

  static password(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError('пароль')
    }

    if (value.length < 8) {
      return 'Введите не менее 8 символов'
    }

    return null
  }

  static oldPassword(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError('старый пароль')
    }

    if (value.length < 8) {
      return 'Введите не менее 8 символов'
    }

    return null
  }

  static newPassword(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError('новый пароль')
    }

    if (value.length < 8) {
      return 'Введите не менее 8 символов'
    }

    return null
  }

  static repeadPassword(repeadPasword: string | undefined): string | null {
    if (!repeadPasword) {
      return 'Подтвердите пароль'
    }

    if (repeadPasword.length < 8) {
      return 'Введите не менее 8 символов'
    }

    return null
  }

  static checkRepead(password: string, repead: string): string | null {
    if (password !== repead) {
      return 'Повторный пароль не совпал'
    }

    return null
  }

  static userId(value: string | undefined): string | null {
    if (!value) {
      return 'Введите ID пользователя'
    }

    return null
  }

  static updateAvatar(photo: {
    id: string
    result: string | ArrayBuffer | null
    file: File | null
  }): string | null {
    if (!photo) {
      return 'Добавьте фото'
    }

    if (photo.result && !photo.file) {
      return 'Выберите новое фото'
    }

    return null
  }
}
