import { PayloadAction } from "@reduxjs/toolkit/dist/createAction";
import { ConfirmInitState } from "./types";

export const reducers = {
  
  showConfirm(
    state: ConfirmInitState,
    action: PayloadAction<{ content: string, title: string }>
  ) {
    const { content, title } = action.payload
    state.content = content;
    state.title = title;
    state.isOpen = true;
  },

  resetConfirm(
    state: ConfirmInitState,
  ) {
    state.content = '';
    state.title = '';
    state.isOpen = false;
  },
  
};
