import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import * as page from '../../pages'
import { NAV } from './nav-links'
import { NotFoundPage } from '../../pages/NotFound/NotFound'
import { MainLayout } from '@/widgets/layouts/main-layout/MainLayout'

export const routerApp = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={`${NAV.MAIN}`} element={<MainLayout />}>
        <Route index element={<page.LoginPage />} />

        <Route path={`${NAV.ACCOUNT}`} element={<page.AccountPage />} />

        <Route path={`${NAV.USER_INFO}`} element={<page.UserInfoPage />} />
        <Route path={`${NAV.USER_EDIT}`} element={<page.UserEditPage />} />
        <Route path={`${NAV.USER_ADD}`} element={<page.UserAddPage />} />
        <Route path={`${NAV.USER_LIST}`} element={<page.UserListPage />} />

        <Route path={`${NAV.WORKS_LIST}`} element={<page.WorksListPage />} />
        <Route path={`${NAV.WORKS_INFO}`} element={<page.WorkInfoPage />} />
        <Route path={`${NAV.WORKS_ADD}`} element={<page.WorkAddPage />} />
        <Route path={`${NAV.WORKS_EDIT}`} element={<page.WorkEditPage />} />
        <Route
          path={`${NAV.METRICS_LIST}`}
          element={<page.MetricsListPage />}
        />
        <Route
          path={`${NAV.METRICS_INFO}`}
          element={<page.MetricsInfoPage />}
        />
        <Route path={`${NAV.METRICS_ADD}`} element={<page.MetricsAddPage />} />
        <Route
          path={`${NAV.METRICS_EDIT}`}
          element={<page.MetricsEditPage />}
        />
        <Route path={`${NAV.ORDERS_LIST}`} element={<page.OrdersListPage />} />
        <Route path={`${NAV.EMAIL}`} element={<page.EmailPage />} />
        <Route path={`${NAV.ORDER_INFO}`} element={<page.OrderInfoPage />} />
        <Route
          path={`${NAV.REVIEWS_LIST}`}
          element={<page.ReviewsListPage />}
        />
        <Route path={`${NAV.REVIEW_INFO}`} element={<page.ReviewsInfoPage />} />

        <Route
          path={`${NAV.REVIEWS_EDIT}`}
          element={<page.ReviewsEditPage />}
        />
        <Route path={`${NAV.REVIEW_ADD}`} element={<page.ReviewsAddPage />} />

        <Route
          path={`${NAV.SERVICES_SCHEMA}`}
          element={<page.ServiceSchema />}
        />

        <Route
          path={`${NAV.SERVICES_LIST}`}
          element={<page.ServicesListPage />}
        />
        <Route path={`${NAV.SERVICES_INFO}`} element={<page.ServicesInfo />} />
        <Route
          path={`${NAV.SERVICES_ADD}`}
          element={<page.ServicesAddPage />}
        />
        <Route
          path={`${NAV.SERVICES_EDIT}`}
          element={<page.ServicesEditPage />}
        />
        <Route path={`${NAV.SEO_LIST}`} element={<page.SeoListPage />} />
        <Route path={`${NAV.SEO_INFO}`} element={<page.SeoInfo />} />
        <Route path={`${NAV.SEO_ADD}`} element={<page.SeoAddPage />} />
        <Route path={`${NAV.SEO_EDIT}`} element={<page.SeoEditPage />} />
        <Route
          path={`${NAV.FEEDBACK_LIST}`}
          element={<page.FeedBackListPage />}
        />
        <Route
          path={`${NAV.FEEDBACK_EDIT}`}
          element={<page.FeedBackEditPage />}
        />

<Route
          path={`${NAV.PRICES_LIST}`}
          element={<page.PricesList />}
        />
        <Route
          path={`${NAV.PRICES_EDIT}`}
          element={<page.PricesEditPage />}
        />
             <Route
          path={`${NAV.PRICES_ADD}`}
          element={<page.PricesAdd />}
        />
        <Route
          path={`${NAV.EMAIL_TEMPLATES}`}
          element={<page.EmailTemplateListPage />}
        />
        <Route
          path={`${NAV.EMAIL_HISTORY}`}
          element={<page.EmailHistoryListPage />}
        />
        <Route
          path={`${NAV.EMAIL_CREATE}`}
          element={<page.EmailTemplateCreatePage />}
        />
        <Route
          path={`${NAV.EMAIL_EDIT}`}
          element={<page.EmailTemplateEditPage />}
        />

        <Route path={`${NAV.LOGS_LIST}`} element={<page.AppLogsPage />} />

        {/* <Route path={`${NAV.APP_STATISTICS}`}  element={<page.AppStatisticsPage />} /> */}
        <Route
          path={`${NAV.APP_STATISTICS_EVENTS}`}
          element={<page.AppStatisticsEventsListPage />}
        />
        <Route
          path={`${NAV.APP_STATISTICS_ADD}`}
          element={<page.AppStatisticsEventsAddPage />}
        />
        <Route
          path={`${NAV.APP_STATISTICS_EDIT}`}
          element={<page.AppStatisticsEventsEditPage />}
        />

        <Route path={`${NAV.LOGS}`} element={<page.LogsPage />} />
        <Route
          path={`${NAV.COMMON_SETTINGS}`}
          element={<page.CommonSettingsPage />}
        />
        <Route
          path={`${NAV.UPDATE_SYSTEM}`}
          element={<page.UpdateSystemPage />}
        />

        <Route path={`${NAV.SETTINGS}`} element={<page.SettingsExamplPage />} />

        <Route
          path={`${NAV.CONFIGURATION_KEYS}`}
          element={<page.ConfigurationKeysListPage />}
        />
        <Route
          path={`${NAV.CONFIGURATION_KEYS_ADD}`}
          element={<page.ConfigurationKeysAddPage />}
        />
        <Route
          path={`${NAV.CONFIGURATION_KEYS_EDIT}`}
          element={<page.ConfigurationKeysEditPage />}
        />

        <Route path={`${NAV.NO_PAGE}`} element={<NotFoundPage />} />
        {/* ----------------------Template------------------------ */}
      </Route>
    </>,
  ),
)
