import { lazy } from 'react'

export const AccountPage = lazy(() => import('./Account/Account'))

export const UserInfoPage = lazy(() => import('./User/user-info/UserInfo'))
export const UserEditPage = lazy(() => import('./User/user-edit/UserEdit'))
export const UserAddPage = lazy(() => import('./User/user-add/UserAdd'))
export const UserListPage = lazy(() => import('./User/UserList'))

export const MetricsInfoPage = lazy(
  () => import('./Metrics/metrics-info/MetricsInfo'),
)
export const MetricsEditPage = lazy(
  () => import('./Metrics/metrics-edit/MetricsEdit'),
)
export const MetricsAddPage = lazy(
  () => import('./Metrics/metrics-add/MetricsAdd'),
)
export const MetricsListPage = lazy(() => import('./Metrics/MetricsList'))
export const WorksListPage = lazy(() => import('./Works/WorksList'))
export const WorkAddPage = lazy(() => import('./Works/work-add/WorkAdd'))
export const WorkEditPage = lazy(() => import('./Works/work-edit/WorkEdit'))
export const WorkInfoPage = lazy(() => import('./Works/work-info/WorkInfo'))
export const ServicesListPage = lazy(() => import('./Services/ServiceList'))
export const ServicesAddPage = lazy(
  () => import('./Services/service-add/ServiceAdd'),
)
export const ServicesEditPage = lazy(
  () => import('./Services/service-edit/ServiceEdit'),
)
export const ServicesInfo = lazy(
  () => import('./Services/service-info/ServiceInfo'),
)

export const ServiceSchema = lazy(() => import('./Services/ServiceSchema'))

export const SeoListPage = lazy(() => import('./SEO/SeoList'))
export const SeoAddPage = lazy(() => import('./SEO/seo-add/SeoAdd'))
export const SeoEditPage = lazy(() => import('./SEO/seo-edit/SeoEdit'))
export const SeoInfo = lazy(() => import('./SEO/seo-info/SeoInfo'))

export const EmailPage = lazy(() => import('./Templates/Email/Email'))
export const OrdersListPage = lazy(() => import('./Orders/OrdersList'))
export const OrderInfoPage = lazy(
  () => import('./Orders/orders-info/OrderInfo'),
)
export const ReviewsListPage = lazy(() => import('./Reviews/ReviewsList'))
export const ReviewsInfoPage = lazy(
  () => import('./Reviews/reviews-info/ReviewInfo'),
)
export const ReviewsAddPage = lazy(
  () => import('./Reviews/reviews-add/ReviewsAdd'),
)
export const ReviewsEditPage = lazy(
  () => import('./Reviews/reviews-edit/ReviewsEdit'),
)

export const PricesEditPage = lazy(() => import('./Prices/PricesEdit'))
export const PricesList = lazy(() => import('./Prices/PricesList'))
export const PricesAdd = lazy(() => import('./Prices/PricesAdd'))
export const FeedBackEditPage = lazy(() => import('./FeedBacks/FeedBackEdit'))
export const FeedBackListPage = lazy(() => import('./FeedBacks/FeedBackList'))
export const EmailTemplateListPage = lazy(
  () => import('./Templates/Email-Templates/EmailTemplateList'),
)
export const EmailHistoryListPage = lazy(
  () => import('./Templates/Email-Hstory/EmailHistoryList'),
)
export const EmailTemplateCreatePage = lazy(
  () => import('./Templates/Email-Templates/EmailTemplateCreate'),
)
export const EmailTemplateEditPage = lazy(
  () => import('./Templates/Email-Templates/EmailTemplateEdit'),
)
export const AppLogsPage = lazy(() => import('./AppWork/AppLogs'))
export const AppStatisticsPage = lazy(
  () => import('./AppStatistics/AppStatistics'),
)
export const AppStatisticsEventsListPage = lazy(
  () => import('./AppStatistics/AppStatisticsEventsList'),
)
export const AppStatisticsEventsAddPage = lazy(
  () => import('./AppStatistics/AppStatisticsEventsAdd'),
)
export const AppStatisticsEventsEditPage = lazy(
  () => import('./AppStatistics/AppStatisticsEventsEdit'),
)
export const LogsPage = lazy(() => import('./Systems/Logs/Logs'))
export const CommonSettingsPage = lazy(
  () => import('./Systems/CommonSettings/CommonSettings'),
)
export const UpdateSystemPage = lazy(
  () => import('./Systems/UpdateSystem/UpdateSystem'),
)
export const SettingsExamplPage = lazy(
  () => import('./Settings/SettingsExampl'),
)
export const ConfigurationKeysListPage = lazy(
  () => import('./ConfigurationKeys/ConfigurationKeysList'),
)
export const ConfigurationKeysAddPage = lazy(
  () => import('./ConfigurationKeys/ConfigurationKeysAdd'),
)
export const ConfigurationKeysEditPage = lazy(
  () => import('./ConfigurationKeys/ConfigurationKeysEdit'),
)
export const LoginPage = lazy(() => import('./Login/Login'))
//------Template---------
